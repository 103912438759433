<template>
  <div>
    <!-- payment-details -->
    <coming-soon />
  </div>
</template>

<script>
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}payment-details`,
  data() {
    return {
      payment: null
    };
  },
  mounted() {
    this.findPayment();
  },
  methods: {
    findPayment() {
      const id = this.$route.params.payment;
      this.payment =
        this.$store.getters.getAPendingPayment(id) ||
        this.$store.getters.getAPaidPayment(id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
